@font-face {
	font-family: 'Conv_0 Badr';
	src: url('/assets/fonts/0 Badr.eot');
	src: url('/assets/fonts/0 Badr.woff') format('woff'), 
         url('/assets/fonts/0 Badr.ttf') format('truetype'), 
         url('/assets/fonts/0 Badr.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_0 Tehran';
	src: url('/assets/fonts/0 Tehran.eot');
	src: url('/assets/fonts/0 Tehran.woff') format('woff'), 
         url('/assets/fonts/0 Tehran.ttf') format('truetype'), 
         url('/assets/fonts/0 Tehran.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'BHamid';
  src: url('/assets/fonts/BHamid.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/BHamid.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/BHamid.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'BLotus';
  src: url('/assets/fonts/BLotus.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/BLotus.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/BLotus.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'BMitra';
  src: url('/assets/fonts/BMitra.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/BMitra.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/BMitra.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar1';
  src: url('/assets/fonts/Entezar1_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar1_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar1_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar2';
  src: url('/assets/fonts/Entezar2_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar2_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar2_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar3';
  src: url('/assets/fonts/Entezar3_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar3_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar3_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar4';
  src: url('/assets/fonts/Entezar4_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar4_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar4_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar5';
  src: url('/assets/fonts/Entezar5_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar5_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar5_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'Entezar6';
  src: url('/assets/fonts/Entezar6_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/Entezar6_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/Entezar6_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarB3';
  src: url('/assets/fonts/EntezarB3_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarB3_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarB3_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarB4';
  src: url('/assets/fonts/EntezarB4_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarB4_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarB4_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarC';
  src: url('/assets/fonts/EntezarC_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarC_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarC_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarC3';
  src: url('/assets/fonts/EntezarC3_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarC3_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarC3_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarD';
  src: url('/assets/fonts/EntezarD_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarD_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarD_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarD3';
  src: url('/assets/fonts/EntezarD3_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarD3_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarD3_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarD5';
  src: url('/assets/fonts/EntezarD5_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarD5_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarD5_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarD6';
  src: url('/assets/fonts/EntezarD6_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarD6_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarD6_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarD8';
  src: url('/assets/fonts/EntezarD8_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarD8_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarD8_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarE2';
  src: url('/assets/fonts/EntezarE2_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarE2_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarE2_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarE3';
  src: url('/assets/fonts/EntezarE3_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarE3_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarE3_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}

@font-face {
  font-family: 'EntezarF2';
  src: url('/assets/fonts/EntezarF2_v2.0.1.eot?#') format('eot'),  /* IE6–8 */
       url('/assets/fonts/EntezarF2_v2.0.1.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
       url('/assets/fonts/EntezarF2_v2.0.1.ttf') format('truetype');  /* Saf3—5, Chrome4+, FF3.5, Opera 10+ */
}


.farsi-lotus {
    font-family: BLotus, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.farsi-mitra {
    font-family: BMitra, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.farsi-badr {
    font-family: 'Conv_0 Badr', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.farsi-tehran {
    font-family: 'Conv_0 Tehran', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.farsi-entezar1 {
    font-family: Entezar1, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.farsi-txt-lg {
	font-size: 40px;
}

.farsi-txt-md {
	font-size: 30px;
}

.farsi-txt-sm {
	font-size: 20px;
}

.farsi-txt-xs {
	font-size: 10px;
}

